import React, { useEffect, useState } from "react"
import { Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Parallax } from "react-scroll-parallax"
import scrollTo from "gatsby-plugin-smoothscroll"

// Hooks
import useTranslation from "../hooks/useTranslation"
import useCountryCode from "../hooks/useCountryCode"

// Icons
import CreditCardIcon from "../assets/icons/creditcards.svg"
import DashboardIcon from "../assets/icons/dashboard.svg"
import ClockIcon from "../assets/icons/chrono.svg"
import TwispayLogo from "../assets/icons/twispay-logo.svg"
import ArrowRightIcon from "../assets/icons/arrow.svg"
import ThreeDotsIcon from "../assets/icons/three-dots.svg"
import LockIcon from "../assets/icons/lock.svg"
import GlobeIcon from "../assets/icons/global-curriencies.svg"
import QuestionMark from "../assets/icons/question-mark-round.svg"

// Images
import PaymentLink from "../assets/home-payment-links.svg"
import PaperPlane from "../assets/home-payment-links-2.svg"
import IllustrationBoy from "../assets/illustration-boy.svg"
import BananaCard from "../assets/card-banana.svg"
import ControllerCard from "../assets/card-controller.svg"
import ShoeCard from "../assets/card-shoe.svg"
import BurgerCard from "../assets/card-burger.svg"
import TshirtCard from "../assets/card-tshirt.svg"
import PayingCard from "../assets/card-paying.svg"
import IntergrationPayout from "../assets/home-intergration-payout.svg"
import PaintIcon from "../assets/home-paint.inline.svg"
import HandclickIcon from "../assets/home-handclick.inline.svg"
import WalletIcon from "../assets/wallet-2-color.svg"
import TwispayLogoAlt from "../assets/twispay-logo-alt.svg"
import TwispayLines from "../assets/home_calculator-lines.inline.svg"
import MainConnector from "../assets/home_main-connector.inline.svg"
import MainConnectorMobile from "../assets/home_main-connector-mobile.inline.svg"
import blueairLogo from "../assets/logos/blueair.png"
import AVstoreLogo from "../assets/logos/AVstore.jpg"
import fit4youLogo from "../assets/fit4you_logo.png"

// Logos
import VisaLogo from "../assets/logos/visa.svg"
import MastercardLogoFull from "../assets/logos/mastercard-full.svg"
import MastercardLogo from "../assets/logos/mastercard.svg"
// import GiropayLogo from "../assets/logos/giropay.svg"
// import SepaLogo from "../assets/logos/sepa.svg"
// import IdealLogo from "../assets/logos/ideal.svg"
// import PostfinanceLogo from "../assets/logos/postfinance.svg"
// import SofortLogo from "../assets/logos/sofort.svg"
// import PaysafeLogo from "../assets/logos/paysafe.svg"
import PCILogo from "../assets/logos/pci-dss.svg"
import ShopifyLogo from "../assets/logos/shopify-dark-full.inline.svg"
import WooCommerceLogo from "../assets/logos/woocommerce-dark-full.inline.svg"
import MagentoLogo from "../assets/logos/magento-dark-full.inline.svg"
import PrestaShopLogo from "../assets/logos/prestashop-dark-full.inline.svg"
import OpencartLogo from "../assets/logos/opencart-dark-full.inline.svg"

// Styles
import "../styles/pages/index.scss"

// Components
import SectionTitle from "../components/section-title"
// import BlackFriday from "./black-friday"

const HeroImagesWrapperVariants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

const HeroImageVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

// In-page Components
const TwispayCalculatorItems = () => (
  <div className="twispay-items">
    <img src={TwispayLogoAlt} alt="Twispay alt" />
    <TwispayLines />
  </div>
)

// Sections
const HeroSection = () => {
  const { t } = useTranslation()
  const { countryCode, continentCode } = useCountryCode()

  useEffect(() => {
    function cookieHubHidden() {
      let element = document.querySelector("body > div:nth-child(6)")
      if (element) {
        element.style.visibility = "hidden"
      } else {
        setTimeout(cookieHubHidden, 100)
      }
    }
    setTimeout(cookieHubHidden, 100)
  }, [])

  const images = [
    {
      src: IllustrationBoy,
      x: [0, 0],
      y: [0, 0],
      alt: "Illustration Boy",
    },

    {
      src: ControllerCard,
      x: [20, -20],
      y: [0, 0],
      alt: "Controller Card",
    },
    {
      src: BananaCard,
      x: [30, -30],
      y: [-30, 30],
      alt: "Banana Card",
    },
    {
      src: ShoeCard,
      x: [10, -10],
      y: [-10, 10],
      alt: "Shoe Card",
    },
    {
      src: BurgerCard,
      x: [-10, 10],
      y: [-30, 50],
      alt: "Burger Card",
    },
    {
      src: TshirtCard,
      x: [-50, 50],
      y: [-10, 10],
      alt: "Tshirt Card",
    },
    {
      src: PayingCard,
      x: [0, 0],
      y: [10, -10],
      alt: "Paying Card",
    },
  ]
  return (
    <section className="section is-hero">
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            {/* {countryCode === "ro" ? (
              <Link
                className=" is-flex is-vcentered is-hspaced has-text-white is-size-7-mobile is-alert-link"
                to="/offline-2-online"
              >
                {t(
                  "Grow online! Benefit from competitive rates and a fully digital onboarding process?"
                )}{" "}
                <span
                  style={{ height: 12, width: 12 }}
                  className="icon has-margin-left-3"
                >
                  <ArrowRightIcon />
                </span>
              </Link>
            ) : (
              <></>
            )} */}

            {/*countryCode === "ro" && (
              <Link to="/summer" className="news-link">
                <span>Vara incepe cu o relaxare de preț</span>
                <ArrowRightIcon />
                <em>Află mai multe</em>
              </Link>
            )}*/}

            {countryCode !== "ro" && continentCode === "EU" ? (
              <Link
                style={{ maxWidth: 232 }}
                className=" is-flex is-vcentered is-hspaced has-text-white is-size-7-mobile is-alert-link is-campaign-styled"
                to="/black-friday"
              >
                {t("black-friday_hero_title")}
                <span
                  style={{ height: 12, width: 12 }}
                  className="icon has-margin-left-3"
                >
                  <ArrowRightIcon />
                </span>
              </Link>
            ) : (
              <></>
            )}
            <h1 className="title main-title has-text-white has-margin-top-5 is-spaced has-margin-bottom-3-mobile">
              {t("home_hero_title")}
            </h1>
            <h2 className="subtitle has-text-white main-subtitle is-6 is-hidden-mobile">
              {t("home_hero_subtitle")}
            </h2>
            <h2 className="subtitle has-text-white main-subtitle is-6 is-hidden-tablet">
              {t("home_hero_subtitle_mobile")}
            </h2>
            <div className="is-flex is-vcentered is-hidden-mobile">
              <button
                onClick={() => scrollTo("#features")}
                className="button is-size-6 is-blue is-rounded "
              >
                {t("home_hero_CTA")}
              </button>
            </div>
          </div>
          <div className="column">
            <motion.div
              className="images-wrapper"
              variants={HeroImagesWrapperVariants}
              initial="hidden"
              animate="visible"
            >
              {images.map((image, i) => (
                <motion.div
                  key={image.alt}
                  variants={HeroImageVariants}
                  className={`image-wrapper img-${i + 1} ${image.alt !==
                    "Illustration Boy" &&
                    image.alt !== "Paying Card" &&
                    "is-hidden-mobile"}`}
                >
                  <Parallax x={image.x} y={image.y} tagOuter="div">
                    <img src={image.src} alt={image.alt} />
                  </Parallax>
                </motion.div>
              ))}
            </motion.div>
            <div className="is-flex is-centered-centered is-hidden-tablet hero-cta-mobile">
              <button
                onClick={() => scrollTo("#features")}
                className="button is-size-6 is-dark-blue is-rounded "
              >
                {t("home_hero_CTA_mobile")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section2 = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <CreditCardIcon />,
      title: t("home_section2_item1_title"),
      subtitle: t("home_section2_item1_subtitle"),
    },
    {
      icon: <DashboardIcon />,
      title: t("home_section2_item2_title"),
      subtitle: t("home_section2_item2_subtitle"),
    },
    {
      icon: <ClockIcon />,
      title: t("home_section2_item3_title"),
      subtitle: t("home_section2_item3_subtitle"),
    },
  ]
  return (
    <section
      id="eCommerce"
      className="section has-margin-bottom-6 section-2 has-margin-bottom-0-mobile"
    >
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-10">
            <div className="columns has-text-centered-tablet ">
              {items.map(item => (
                <div key={item.title} className="column">
                  <span className="icon is-larger has-margin-bottom-5 has-margin-bottom-3-mobile has-text-primary">
                    {item.icon}
                  </span>
                  <h4 className="title is-3 item-title is-spaced">
                    {item.title}
                  </h4>
                  <p className="subtitle is-6 has-text-grey-dark item-subtitle ">
                    {item.subtitle}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const Section3 = () => {
  const logos = [
    { icon: MastercardLogoFull, alt: "Mastercard" },
    { icon: VisaLogo, alt: "Visa" },
    // { icon: SofortLogo, alt: "Sofort" },
    // { icon: GiropayLogo, alt: "Giropay" },
    // { icon: PaysafeLogo, alt: "Paysafe" },
    // { icon: SepaLogo, alt: "Sepa" },
    // { icon: IdealLogo, alt: "Ideal" },
    // { icon: PostfinanceLogo, alt: "Postfinance" },
  ]
  const { t } = useTranslation()
  return (
    <section className="section section-3 has-margin-bottom-7">
      <TwispayLines className="is-hidden-tablet twispay-lines" />
      <div style={{ position: "relative" }} className="container">
        <div className="columns is-centered is-vcentered">
          <div className="column is-7">
            <div className="is-flex is-vcentered left-wrapper">
              <div className="payout-image ">
                <img src={IntergrationPayout} alt="Intergration Payout" />
              </div>
              <MainConnector className="main-connector is-hidden-touch" />
              <MainConnectorMobile className="main-connector-mobile is-hidden-desktop" />
              <div className="logos-wrapper">
                {logos.map(logo => (
                  <div
                    key={logo.alt}
                    className="logo-card is-card is-flex is-centered-centered"
                  >
                    <img src={logo.icon} alt={logo.alt} />
                  </div>
                ))}
                <Link
                  className="more-card is-card is-flex is-centered-centered has-text-secondary"
                  to="/faq#accepted-payment-methods"
                >
                  <span className="icon ">
                    <ThreeDotsIcon />
                  </span>
                  <span className="text">{t("home_section3_more_btn")}</span>
                </Link>
                {[...Array(12)].map((_, i) => (
                  <div
                    key={i}
                    className={`connector connector-${i} ${
                      i <= 5 ? "is-horizontal" : "is-vertical"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* <div className="column is-3-desktop is-5-tablet  has-text-centered-mobile right-wrapper">
            <h1 className="title is-2 is-spaced ">
              {t("home_section3_title")}
            </h1>
            <p className="subtitle has-text-grey-dark is-6 has-margin-bottom-6 has-margin-bottom-5-mobile">
              {t("home_section3_subtitle")}
            </p>
            <Link to="/pricing" className="button is-rounded is-primary">
              {t("home_section3_CTA")}
            </Link>
          </div> */}
        </div>
      </div>
    </section>
  )
}

const Section4 = ({ imageFluid, dotsFluid }) => {
  const { t } = useTranslation()
  const modulesLogos = [
    <ShopifyLogo />,
    <WooCommerceLogo />,
    <MagentoLogo />,
    <PrestaShopLogo />,
    <OpencartLogo />,
  ]
  return (
    <section className="section section-4 has-margin-bottom-5 has-margin-bottom-7-mobile">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-4-fullhd">
            <h1 className="title main-title has-text-white has-margin-bottom-4-mobile has-text-centered-mobile">
              {t("home_section4_title")}
            </h1>
            <p className="is-size-6 has-text-centered-mobile text-1">
              {t("home_section4_text-1")}
            </p>
            <br />
            <p className="has-margin-bottom-6 is-size-6 is-hidden-mobile">
              {t("home_section4_text-2")}
            </p>
            <Link
              to="/integrations"
              className="button is-primary is-rounded has-margin-right-4 is-hidden-mobile"
            >
              {t("home_section4_CTA")}
            </Link>

            <a
              href="http://docs.twispay.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-styleless has-text-success has-text-weight-normal is-hidden-mobile"
            >
              {t("home_section4_link")}

              <span
                style={{ height: 13, width: 13 }}
                className="icon  has-margin-left-3"
              >
                <ArrowRightIcon />
              </span>
            </a>
          </div>
          <div className="column is-6-fullhd ">
            <div className="img-wrapper">
              <div className="dots is-hidden-mobile">
                <Img fluid={dotsFluid} />
              </div>
              <Img className="main-img " fluid={imageFluid} />
            </div>
            <div className="modules-wrapper">
              {modulesLogos.map((module, i) => (
                <div key={i} className="module-wrapper">
                  {module}
                </div>
              ))}
              <Link
                className="more-button is-flex is-centered-centered has-text-secondary"
                to="/integrations"
              >
                <span className="icon ">
                  <ThreeDotsIcon />
                </span>
                <span className="text">{t("home_section4_more_btn")}</span>
              </Link>
            </div>
            <p className="has-margin-top-6 is-hidden-tablet text-2 has-margin-bottom-5">
              {t("home_section4_text-2")}
            </p>
            <Link
              to="/integrations"
              className="button is-primary is-rounded is-hidden-tablet"
            >
              {t("home_section4_CTA")}
            </Link>

            <a
              href="http://docs.twispay.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-styleless has-text-success has-text-weight-normal is-hidden-tablet"
            >
              {t("home_section4_link")}
              <span
                style={{ height: 13, width: 13 }}
                className="icon  has-margin-left-3"
              >
                <ArrowRightIcon />
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section5 = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-5 has-margin-bottom-6-mobile">
      <div className="container is-hidden-touch">
        <div className="columns is-centered">
          <div className="column is-10-fullhd">
            <div className="paper-plane">
              <img src={PaperPlane} alt="Paper Plane" />
            </div>
            <div className="is-card">
              <div className="columns is-vcentered is-variable is-8">
                <div className="column">
                  <div className="is-flex is-vcentered">
                    <div className="icon-wrapper has-margin-right-4">
                      <img src={PaymentLink} alt="Payment Links" />
                    </div>
                    <div className="has-text-centered-mobile">
                      <h5 className=" title is-3 title-wrapper">
                        {t("home_section5_title")}
                      </h5>

                      <p className="is-size-6 has-text-grey-light">
                        {t("home_section5_subtitle")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column is-offset-1 is-1 has-text-centered is-3-tablet">
                  <Link
                    to="/payment-links"
                    className="button is-primary has-text-weight-bold is-rounded"
                  >
                    {t("home_section5_CTA")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container is-hidden-desktop">
        <div style={{ maxWidth: 500, margin: "auto" }}>
          <div className="title-wrapper is-flex is-vcentered has-margin-bottom-4">
            <div className="icon-wrapper has-margin-right-4 ">
              <img src={PaymentLink} alt="Payment Links" />
            </div>
            <h5 className="title">{t("home_section5_title_mobile")}</h5>
          </div>
          <p className="subtitle-wrapper has-margin-bottom-5">
            {t("home_section5_subtitle")}
          </p>
          <div className="has-text-centered button-wrapper">
            <Link
              to="/payment-links"
              className="button is-primary has-text-weight-bold is-rounded"
            >
              {t("home_section5_CTA")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section6 = ({ logos }) => {
  const { t } = useTranslation()

  const initialTheme = [
    // {
    //   name: t("home_section6_payout_theme1_title"),
    //   color: "#ffffff",
    //   logo: blueairLogo,
    //   cardLogo: MastercardLogo,
    //   cardLogoWidth: 50,
    //   textColor: "#575f7f",
    //   disabledColor: "rgb(87, 95, 127,0.7)",
    //   creditCardNo: "3459 2210",
    //   focusColor: "#4369fb",
    //   btnColor: "#012f9d",
    //   btnBorderColor: "#0097db",
    //   background: "#ffffff",
    //   logoWidth: 179,
    // },
    {
      name: t("home_section6_payout_theme2_title"),
      color: "#d2d4d9",
      logo: AVstoreLogo,
      cardLogo: VisaLogo,
      cardLogoWidth: 70,
      textColor: "white",
      disabledColor: "rgb(21, 23, 26, 0.7)",
      creditCardNo: "4012 8888",
      focusColor: "#d76529",
      btnColor: "#d76529",
      btnBorderColor: "#ff864c",
      background: "#15171a",
      logoWidth: 221,
      invertFooter: true,
    },
    {
      name: t("home_section6_payout_theme3_title"),
      color: "#dde9da",
      logo: fit4youLogo,
      textColor: "#444445",
      disabledColor: "rgb(130, 134, 130,0.7)",
      creditCardNo: "2675 9172",
      focusColor: "#79ca0d",
      btnColor: "#359434",
      btnBorderColor: "#7acb0d",
      background: "#fafafc",
      logoWidth: 334,
    },
  ]
  const [themes, setThemes] = useState(initialTheme)
  // const colors = ["#ffffff", "#d2d4d9", "#dde9da"]
  const colors = ["#d2d4d9", "#dde9da"]
  const items = [
    {
      icon: <GlobeIcon />,
      title: t("home_section6_item1_title"),
      subtitle: t("home_section6_item1_subtitle"),
    },
    {
      icon: <HandclickIcon />,
      title: t("home_section6_item2_title"),
      subtitle: t("home_section6_item2_subtitle"),
    },
    {
      icon: <PaintIcon />,
      title: t("home_section6_item3_title"),
      subtitle: t("home_section6_item3_subtitle"),
    },
  ]
  function selectTheme(color) {
    if (themes[0].color === color) return
    const tempThemes = [...themes]
    const selectedThemeIndex = themes.findIndex(theme => theme.color === color)
    const selectedTheme = tempThemes.splice(selectedThemeIndex, 1)
    setThemes([selectedTheme[0], ...tempThemes])
  }
  function nextTheme() {
    const tempThemes = [...themes]
    const firstTheme = tempThemes.shift()
    // if (themes[0].color === firstTheme.color) return
    setThemes([...tempThemes, firstTheme])
  }
  return (
    <section className="section section-6 has-margin-bottom-8 has-margin-bottom-0-mobile">
      <div id="features" className="container">
        <SectionTitle
          title={t("home_section6_title")}
          subtitle={t("home_section6_subtitle")}
          subtitleWidth={700}
          titleColor="primary"
          className="has-margin-bottom-7 is-hidden-mobile"
        />
        <SectionTitle
          title={t("home_section6_title")}
          subtitle={t("home_section6_items_subtitle")}
          titleColor="primary"
          className="has-margin-bottom-6 is-hidden-tablet"
        />
        <div className="columns is-centered is-vcentered">
          <div
            onMouseEnter={nextTheme}
            role="presentation"
            className="column is-5-fullhd is-6-tablet is-hidden-mobile"
          >
            <div className="colors-wrapper is-flex has-margin-bottom-5">
              {colors.map(color => (
                <div
                  key={color}
                  style={{ background: color }}
                  className={`color-dot has-margin-right-4 ${color ===
                    themes[0].color && "is-selected"}`}
                  onMouseOver={() => selectTheme(color)}
                  onFocus={() => selectTheme(color)}
                  role="presentation"
                >
                  {color === themes[0].color && (
                    <span className="tooltip is-flex is-centered-centered has-text-white is-size-7">
                      {themes[0].name}
                    </span>
                  )}
                </div>
              ))}
            </div>
            {themes.map((theme, i) => (
              <motion.div
                key={theme.name}
                layoutTransition={{
                  type: "spring",
                  damping: 30,
                  stiffness: 200,
                }}
                className={`card-wrapper card-${i}`}
                style={{
                  background: i === 0 && theme.background,
                  marginRight: i !== 0 && `${5 * i}%`,
                  zIndex: themes.length - i,
                }}
              >
                {i === 0 ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div
                      style={{ width: theme.logoWidth }}
                      className="logo-wrapper has-margin-bottom-5"
                    >
                      <img src={theme.logo} alt="logo" />
                    </div>
                    <p
                      style={{ color: theme.textColor }}
                      className="sub-text is-size-5 has-text-centered has-margin-bottom-5"
                    >
                      {t("home_section6_payout_title")}
                    </p>
                    <div className="control  has-icons-right">
                      <div
                        style={{
                          borderColor: theme.focusColor,
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        className="input is-medium"
                      >
                        <span
                          style={{ fontSize: 10, marginBottom: 0 }}
                          className="label"
                        >
                          Card Number
                        </span>
                        <span>
                          <span style={{ opacity: 0.65 }}>
                            {" "}
                            {theme.creditCardNo}
                          </span>{" "}
                          <span style={{ opacity: 0.16 }}>0000 0000</span>
                        </span>
                      </div>
                      {theme.cardLogo && (
                        <span className="icon is-right has-margin-right-3 is-large">
                          <img
                            width={theme.cardLogoWidth}
                            src={theme.cardLogo}
                            alt="logo"
                          />
                        </span>
                      )}
                    </div>
                    <div
                      style={{ marginBottom: 0 }}
                      className="columns is-gapless "
                    >
                      <div className="column">
                        <div className="control">
                          <div
                            style={{ color: theme.disabledColor }}
                            className="input is-medium is-disabled"
                          >
                            Expiry Date
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="control has-icons-right">
                          <div
                            style={{ color: theme.disabledColor }}
                            className="input is-medium is-disabled"
                          >
                            CVC
                            <span
                              style={{ color: "#fff" }}
                              className="icon is-right is-small"
                            >
                              <QuestionMark />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="control has-margin-bottom-5">
                      <div
                        style={{ color: theme.disabledColor }}
                        className="input is-medium is-disabled"
                      >
                        Name on the Credit Card
                      </div>
                    </div>
                    <button
                      style={{
                        background: theme.btnColor,

                        borderBottom: `3px solid ${theme.btnBorderColor}`,
                      }}
                      className="button payout-btn is-fullwidth has-text-white is-medium has-margin-bottom-4"
                    >
                      <span style={{ width: 18, height: 22 }} className="icon">
                        <LockIcon />
                      </span>
                      <span>
                        {t("home_section6_payout_button_text")} 98.99 EUR
                      </span>
                    </button>
                    <div
                      className={`is-flex is-centered-centered payout-footer ${theme.invertFooter &&
                        "is-inverted"}`}
                    >
                      <img
                        style={{ width: 50, height: 16 }}
                        src={VisaLogo}
                        alt="Visa"
                      />
                      <img
                        className="has-margin-left-3 has-margin-right-3"
                        style={{ width: 40, height: 28 }}
                        src={MastercardLogoFull}
                        alt="Mastercard"
                      />
                      <img
                        style={{ width: 50, height: 32 }}
                        src={PCILogo}
                        alt="PCI"
                      />
                    </div>
                  </motion.div>
                ) : null}
              </motion.div>
            ))}
          </div>
          <div className="column is-1 is-hidden-mobile"></div>
          <div className="column is-4-fullhd is-5-tablet right-col">
            <h1 className="title main-title is-5 is-spaced is-hidden-mobile">
              {t("home_section6_items_title")}
            </h1>
            <p className="subtitle main-subtitle is-hidden-mobile">
              {t("home_section6_items_subtitle")}
            </p>
            {items.map(item => (
              <div key={item.title} className="columns item-wrapper">
                <div className="column is-3 ">
                  <span className="icon">{item.icon}</span>
                </div>
                <div className="column">
                  <h3 className="title is-spaced">{item.title}</h3>
                  <p className="subtitle is-6 has-text-grey-dark">
                    {item.subtitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const Section7 = () => {
  const { t } = useTranslation()
  const { countryCode } = useCountryCode()

  const [oldPayment, setOldPayment] = useState({
    avgTransaction: 13599,
    currentFee: 2.5,
  })
  const loseValue = (
    (oldPayment.avgTransaction * oldPayment.currentFee) /
    100
  ).toFixed(2)
  const ourRate = countryCode === "ro" ? 0.99 : 1.3
  const savedAmount = ((oldPayment.avgTransaction * ourRate) / 100).toFixed(2)
  return (
    <section className="section section-7">
      <div className="container">
        <SectionTitle
          title={t("home_section7_title")}
          subtitle={t("home_section7_subtitle")}
          className="is-hidden-mobile"
          subtitleWidth={800}
        />
        <h4 className="title title-mobile is-hidden-tablet has-text-centered">
          {t("home_section7_title")}
        </h4>
        <div className="has-text-centered is-hidden-tablet has-margin-bottom-5">
          <img src={WalletIcon} alt="Wallet" />
        </div>
        <div className="columns is-centered">
          <div className="column is-10-fullhd">
            <div className="ad-wrapper is-flex is-hspaced is-vcentered has-margin-bottom-6 is-hidden-mobile">
              <div className="is-flex is-vcentered">
                <span className="icon">
                  <img src={WalletIcon} alt="Wallet" />
                </span>
                <h4 className="title has-text-white">
                  {t("home_section7_ad_text")}
                </h4>
              </div>
              <Link to="/pricing" className="button is-rounded is-primary">
                {t("home_section7_ad_CTA")}
              </Link>
            </div>

            <div className="columns calculator-wrapper">
              <div className="column ">
                <div style={{ height: "100%" }} className="is-card left-card">
                  <h5 className="title has-text-primary has-text-centered">
                    {t("home_section7_left_title")}
                  </h5>

                  <div className="field is-horizontal">
                    <div className="field-label is-flex is-vcentered is-normal">
                      <label className="label" htmlFor="avgTransaction">
                        {t("home_section7_left_label1")}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control has-icons-left">
                          <input
                            className="input is-medium"
                            type="text"
                            aria-label={t("home_section7_left_label1")}
                            id="avgTransaction"
                            value={oldPayment.avgTransaction}
                            onChange={e =>
                              setOldPayment({
                                ...oldPayment,
                                avgTransaction: e.target.value,
                              })
                            }
                          />
                          <span className="icon is-left">
                            <span className="has-text-primary is-size-3">
                              €
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-flex is-vcentered is-normal">
                      <label className="label" htmlFor="currentFee">
                        {t("home_section7_left_label2")}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control has-icons-left">
                          <input
                            className="input is-medium"
                            id="currentFee"
                            type="text"
                            aria-label={t("home_section7_left_label2")}
                            value={oldPayment.currentFee}
                            onChange={e =>
                              setOldPayment({
                                ...oldPayment,
                                currentFee: e.target.value,
                              })
                            }
                          />
                          <span className="icon is-left">
                            <span className="has-text-default is-size-3">
                              %
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-flex is-vcentered  is-normal">
                      <label className="label" htmlFor="loseValue">
                        {t("home_section7_left_label3")}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control has-icons-left">
                          <input
                            className="input is-medium is-static has-text-danger"
                            id="loseValue"
                            type="text"
                            value={loseValue}
                            aria-label={t("home_section7_left_label3")}
                            readOnly
                          />
                          <span className="icon is-left">
                            <span className=" is-size-3 has-text-danger">
                              €
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TwispayCalculatorItems />
              <div className="column">
                <div className="is-card right-card ">
                  <div>
                    <div
                      style={{ color: "#466bfc" }}
                      className="logo-wrapper has-text-centered"
                    >
                      <TwispayLogo />
                    </div>
                    <div className="is-flex-mobile has-margin-bottom-5">
                      <div className="field is-horizontal">
                        <div className="field-label is-flex is-vcentered is-normal">
                          <label className="label" htmlFor="ourRate">
                            {t("home_section7_right_label1")}
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control has-icons-left">
                              <input
                                className="input is-medium is-static has-text-default "
                                id="ourRate"
                                type="text"
                                value={ourRate}
                                onChange={() => {
                                  return
                                }}
                                aria-label={t("home_section7_right_label1")}
                                readOnly
                              />
                              <span className="icon is-left">
                                <span className="has-text-success is-size-3">
                                  %
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field is-horizontal ">
                        <div className="field-label is-flex is-vcentered  is-normal">
                          <label className="label" htmlFor="savedAmount">
                            {t("home_section7_right_label2")}
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control has-icons-left">
                              <input
                                className="input is-medium is-static has-text-default"
                                id="savedAmount"
                                type="text"
                                value={savedAmount}
                                aria-label={t("home_section7_right_label2")}
                                readOnly
                              />
                              <span className="icon is-left">
                                <span className="has-text-success is-size-3">
                                  €
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/pricing"
                    className="button is-success is-medium is-size-6-mobile is-fullwidth has-text-weight-bold is-rounded"
                  >
                    {t("home_section7_right_CTA")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const IndexPage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout stripeClass="is-darkviolet" pageName="homepage" hasMoreHeightStripe>
      <SEO title={`Twispay - ${t("Complete Online Payment Solution")}`} />
      <HeroSection />
      <Section2 />
      <Section3 />
      <Section4
        imageFluid={images.developer.childImageSharp.fluid}
        dotsFluid={images.dots.childImageSharp.fluid}
      />
      <Section5 />
      <Section6 />
      <Section7 />
    </Layout>
  )
}

export const images = graphql`
  query {
    developer: file(relativePath: { eq: "developer-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    dots: file(relativePath: { eq: "dots.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default IndexPage
