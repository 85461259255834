import { useState, useEffect } from "react"

export default function useCountryCode() {
  const [countryCode, setCountryCode] = useState("en")
  const [continentCode, setContinentCode] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    fetch(
      `https://pro.ip-api.com/json?key=zklhHkQCKYhElbG&fields&fields=countryCode,continentCode`
    )
      .then(response => response.json())
      .then(resultData => {
        setCountryCode(resultData.countryCode.toLowerCase())
        setContinentCode(resultData.continentCode)
        setIsLoading(false)
      })
      .catch(err => {
        setCountryCode("en")
        setIsLoading(false)
      })
  }, [])
  return { countryCode, continentCode, isLoading }
}
